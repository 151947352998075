import { useEffect, useRef, useState, useCallback } from 'react';
import type { SetStateAction } from 'react';
import cx from 'classnames';
import { useTranslation } from 'next-i18next';
import { useFilterState } from '../../providers/filter-provider';
import type { HotelAmenityId, Maybe } from '../../gql/types';
import type { ActiveFiltersState } from './filter.constants';
import { useMetrics, useGetMVTSelections } from '../../hooks';
import { SUGGESTED_FILTER_PILLS, GOALS } from '../../constants';
import { useSuggestedFilters } from '../../hooks/use-suggested-filters';
import Icon, { AmenityIcons, type IconProps } from '@dx-ui/osc-icon';
import { HotelAttributeIds } from './filter.constants';
import { isBrowser } from '@dx-ui/utilities-is-browser';
import { getLanguageDirection } from '@dx-ui/utilities-get-language-direction';
import { sendReward } from '@dx-ui/framework-conductrics';

export type AmenityItem = {
  id: string;
  name: string;
  hint?: string | null;
  count?: number;
};
type SuggestedFilterButtonsProps = {
  amenities?: AmenityItem[];
  isConductricsLoaded?: boolean;
  onFilterCriteriaChange: (activeFiltersState: ActiveFiltersState) => void;
  visibleHotels: string[];
  matchId?: Maybe<string>;
  saleFilter?: { showSaleFilter: boolean; saleFilterCount: number };
};

type SuggestedFiltersAmenitiesProps = {
  suggestedFilterOrderedList: AmenityItem[];
  onFilterCriteriaChange: (activeFiltersState: ActiveFiltersState) => void;
  showSaleFilter?: boolean;
  suggestedFilterMVTVariant?: Record<string, boolean>;
};

const DEBOUNCE_DELAY = 100;

/*
 * SuggestedFilterButtons
 * Displays the Suggested Filter Component
 */
export const SuggestedFilterButtons = ({
  amenities = [],
  isConductricsLoaded,
  onFilterCriteriaChange,
  visibleHotels,
  matchId,
  saleFilter,
}: SuggestedFilterButtonsProps) => {
  const { isAtleastOneSuggestedFilter, topSuggestedFilters } = useSuggestedFilters({
    visibleHotels,
    amenities,
    matchId,
    saleFilter,
  });
  const suggestedFiltersOrderedList = Object.values(topSuggestedFilters);

  const { isMVTVariant: suggestedFilterMVTVariant } = useGetMVTSelections({
    agentId: SUGGESTED_FILTER_PILLS.agentId,
    MVTVariants: ['a', 'b', 'c', 'd'],
  });

  const isSuggestedFiltersVariant =
    suggestedFilterMVTVariant.b || suggestedFilterMVTVariant.c || suggestedFilterMVTVariant.d;

  return (
    <>
      {(() => {
        if (isAtleastOneSuggestedFilter || saleFilter?.showSaleFilter) {
          // Aligned Shimmer state with hotel cards using conductrics
          if (!isConductricsLoaded || !amenities.length) return <SuggestedFiltersShimmer />;
          else if (
            isConductricsLoaded &&
            Boolean(visibleHotels.length) &&
            isSuggestedFiltersVariant
          ) {
            return (
              <>
                <SuggestedFiltersTitle />
                <SuggestedFiltersAmenitiesMVT
                  onFilterCriteriaChange={onFilterCriteriaChange}
                  suggestedFilterOrderedList={suggestedFiltersOrderedList}
                  showSaleFilter={saleFilter?.showSaleFilter}
                  suggestedFilterMVTVariant={suggestedFilterMVTVariant}
                />
              </>
            );
          } else if (
            isConductricsLoaded &&
            Boolean(visibleHotels.length) &&
            !isSuggestedFiltersVariant
          ) {
            return (
              <>
                <SuggestedFiltersTitle />
                <SuggestedFiltersAmenities
                  onFilterCriteriaChange={onFilterCriteriaChange}
                  suggestedFilterOrderedList={suggestedFiltersOrderedList}
                  showSaleFilter={saleFilter?.showSaleFilter}
                />
              </>
            );
          } else return null;
        }
      })()}
    </>
  );
};

/*
 * SuggestedFiltersShimmer
 * Returns the Suggested Filters Shimmer State
 */
const SuggestedFiltersShimmer = () => (
  <>
    <div className="flex" data-testid="suggested-filters-shimmer">
      <div className="bg-bg-alt mb-4 h-6 w-32 animate-pulse" />
    </div>
    <div className="flex h-32 lg:h-16">
      <div className="bg-bg-alt mr-2 w-20 animate-pulse lg:mr-1 lg:h-16 lg:w-32" />
      <div className="bg-bg-alt mr-2 w-20 animate-pulse lg:mr-1 lg:h-16 lg:w-32" />
      <div className="bg-bg-alt mr-2 w-20 animate-pulse lg:mr-1 lg:h-16 lg:w-32" />
      <div className="bg-bg-alt mr-2 w-20 animate-pulse lg:mr-1 lg:h-16 lg:w-32" />
    </div>
  </>
);

/*
 * Returns Suggest Filters Title
 */
const SuggestedFiltersTitle = () => {
  const { t } = useTranslation('filters');
  return (
    <h2 className="label mr-1 pb-1 font-semibold tracking-tight rtl:mx-0">
      {t('suggestedFiltersLabel')}
    </h2>
  );
};

/*
 * Returns Suggest Filters Icons and Amenities
 */
const SuggestedFiltersAmenities = ({
  onFilterCriteriaChange,
  suggestedFilterOrderedList,
}: SuggestedFiltersAmenitiesProps) => {
  const { t } = useTranslation('filters');
  const activeFiltersState = useFilterState();
  const [selectedSuggestedFilter, setSelectedSuggestedFilter] = useState('');
  const metrics = useMetrics();

  const resetSuggestedFilter = (
    amenityId: HotelAmenityId | typeof HotelAttributeIds.sale,
    amenityName: string
  ) => {
    const attributeFilters = [...activeFiltersState.attributeFilters];
    const attributeIndex = attributeFilters.indexOf(amenityId);
    const amenityFilters = [...activeFiltersState.amenityFilters];
    const amenityIndex = amenityFilters.indexOf(amenityId);

    if (amenityId === HotelAttributeIds.sale) {
      if (attributeIndex !== -1) attributeFilters.splice(attributeIndex, 1);
      else attributeFilters.push(amenityId);
    } else {
      if (amenityIndex !== -1) amenityFilters.splice(amenityIndex, 1);
      else amenityFilters.push(amenityId);
    }

    const updatedActiveFiltersState = {
      ...activeFiltersState,
      amenityFilters,
      attributeFilters,
    };

    if (
      updatedActiveFiltersState.amenityFilters.includes(amenityId) ||
      updatedActiveFiltersState.attributeFilters.includes(amenityId)
    )
      setSelectedSuggestedFilter(t('removeFilterPillLabelButton', { name: amenityName }));
    else setSelectedSuggestedFilter('');
    onFilterCriteriaChange(updatedActiveFiltersState);
  };

  const handleOnClick = async (
    amenityId: HotelAmenityId | typeof HotelAttributeIds.sale,
    amenityName: string
  ) => {
    resetSuggestedFilter(amenityId, amenityName);
    sendReward(GOALS.suggestedFilterEngagement);

    await metrics.trackSuggestedFilters(amenityId);
  };

  const scrollContainerRef = useRef<HTMLDivElement>(null);

  return (
    <div className="flex pb-2 pt-1" data-testid="suggested-filters">
      <div ref={scrollContainerRef} className="flex overflow-x-auto ltr:ml-1 rtl:mr-1">
        {suggestedFilterOrderedList?.map((amenity) => {
          const amenityListCount = amenity?.count;
          const amenitySelected = activeFiltersState.amenityFilters.includes(amenity?.id ?? '');
          const saleFilterSelected =
            amenity.id === HotelAttributeIds.sale &&
            activeFiltersState.attributeFilters.includes(amenity?.id ?? '');

          return amenityListCount ? (
            <div
              key={amenity?.id}
              className={cx(
                'mr-1 my-1 p-2 lg:first:pl-1 lg:px-px shrink-0 flex justify-center items-start w-20 lg:w-28',
                {
                  'fill-primary text-primary': amenitySelected || saleFilterSelected,
                }
              )}
            >
              <button
                aria-label={`${amenity?.name}  (${amenityListCount})`}
                onClick={() => handleOnClick(amenity?.id as HotelAmenityId, amenity?.name ?? '')}
                type="button"
              >
                <div className="pointer-events-none inline-block size-8">
                  <Icon
                    name={
                      AmenityIcons[amenity.id as HotelAmenityId] ||
                      (amenity.id as IconProps['name'])
                    }
                    size="lg"
                  />
                </div>
                <p className="break-words text-center text-sm font-normal leading-[18px] lg:break-normal">
                  {amenity?.name} <br className="lg:hidden" /> ({amenityListCount})
                </p>
              </button>
            </div>
          ) : null;
        })}

        <div aria-atomic="true" aria-live="polite" className="sr-only">
          {selectedSuggestedFilter}
        </div>
      </div>
    </div>
  );
};

const SuggestedFiltersAmenitiesMVT = ({
  onFilterCriteriaChange,
  suggestedFilterOrderedList,
  suggestedFilterMVTVariant,
}: SuggestedFiltersAmenitiesProps) => {
  const { t } = useTranslation('osc-hotel-details-panel');
  const activeFiltersState = useFilterState();
  const metrics = useMetrics();
  const previousArrowRef = useRef<HTMLButtonElement>(null);
  const nextArrowRef = useRef<HTMLButtonElement>(null);
  const [slideScrollElement, updateSlideScrollRef] = useState<HTMLDivElement | null>(null);
  const scrollAnimationRequestID = useRef(0);
  const isRtl = getLanguageDirection('en') === 'rtl';
  const handleRtl = useCallback((value: number) => (isRtl ? -value : value), [isRtl]);
  const mathRoundKey = isRtl ? 'floor' : 'ceil';
  const scrollManageTimeout = useRef(0);
  const [activeSlideIndex] = useState(0);
  const slideRefs = useRef<HTMLSpanElement[]>([]);

  const getSuggestedPillStyle = (suggestedFilterMVTVariant?: Record<string, boolean>) => {
    if (suggestedFilterMVTVariant?.c)
      return 'btn outline-[#404040] border-[#404040] text-[#404040] align-middle flex content-center rounded-full focus:shadow-none';
    if (suggestedFilterMVTVariant?.d)
      return 'btn outline-[#262626] border-[#262626] text-[#262626] align-middle flex content-center rounded-full focus:shadow-none';
    else
      return 'btn btn-primary-outline focus:border-primary align-middle flex content-center rounded-full focus:shadow-none';
  };
  const suggestedFilterStyle = getSuggestedPillStyle(suggestedFilterMVTVariant);

  const onSlideScrollRefChange = useCallback((node: SetStateAction<HTMLDivElement | null>) => {
    updateSlideScrollRef(node);
  }, []);

  function scrollToOffset(offset: number) {
    if (!isBrowser) return;

    if (scrollAnimationRequestID.current) {
      window.cancelAnimationFrame(scrollAnimationRequestID.current);
    }

    if (!slideScrollElement) return;

    const correctedOffset = handleRtl(offset);

    if (window.matchMedia && window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
      slideScrollElement.scrollLeft = correctedOffset;
    } else {
      const slideScrollLeftStart = Math[mathRoundKey](slideScrollElement.scrollLeft);
      const distance = correctedOffset - slideScrollLeftStart;

      if (distance === 0) return;

      let startTime: number;
      let endTime: number;

      const duration = Math.max(350, Math.abs(distance) * 0.5);
      const step = (timestamp: number) => {
        if (!startTime) {
          startTime = timestamp;
          endTime = startTime + duration;
        }

        if (timestamp >= endTime) {
          slideScrollElement.scrollLeft = correctedOffset;
          scrollAnimationRequestID.current = 0;
        } else {
          const progress = (timestamp - startTime) / duration;

          slideScrollElement.scrollLeft =
            slideScrollLeftStart +
            distance *
              (progress < 0.5 ? 2 * progress * progress : -1 + (4 - 2 * progress) * progress);
          scrollAnimationRequestID.current = window.requestAnimationFrame(step);
        }
      };

      scrollAnimationRequestID.current = window.requestAnimationFrame(step);
    }
  }

  const resetSuggestedFilter = (amenityId: HotelAmenityId | typeof HotelAttributeIds.sale) => {
    const attributeFilters = [...activeFiltersState.attributeFilters];
    const attributeIndex = attributeFilters.indexOf(amenityId);
    const amenityFilters = [...activeFiltersState.amenityFilters];
    const amenityIndex = amenityFilters.indexOf(amenityId);

    if (amenityId === HotelAttributeIds.sale) {
      if (attributeIndex !== -1) attributeFilters.splice(attributeIndex, 1);
      else attributeFilters.push(amenityId);
    } else {
      if (amenityIndex !== -1) amenityFilters.splice(amenityIndex, 1);
      else amenityFilters.push(amenityId);
    }

    const updatedActiveFiltersState = {
      ...activeFiltersState,
      amenityFilters,
      attributeFilters,
    };

    onFilterCriteriaChange(updatedActiveFiltersState);
  };

  /* Testing ouf the scrollLeft from HDP*/
  function scrollLeft() {
    if (!slideScrollElement) return;

    scrollToOffset(
      Math.max(Math.abs(slideScrollElement.scrollLeft) - slideScrollElement.offsetWidth, 0)
    );
  }

  /* Testing out of the scrollRight from HDP */
  function scrollRight() {
    if (!slideScrollElement) return;

    const slideScrollOffsetWidth = slideScrollElement.offsetWidth;

    scrollToOffset(
      Math.min(
        slideScrollElement.scrollWidth - slideScrollOffsetWidth,
        Math.abs(slideScrollElement.scrollLeft) + slideScrollOffsetWidth
      )
    );
  }

  const manageArrowVisibility = useCallback(() => {
    const previousArrow = previousArrowRef.current;
    const nextArrow = nextArrowRef.current;

    if (!slideScrollElement || !previousArrow || !nextArrow) return;

    const slideScrollLeft = Math[mathRoundKey](slideScrollElement.scrollLeft);
    const activeElement = document.activeElement;
    const hidePrevious = isRtl ? slideScrollLeft > -5 : slideScrollLeft < 5;
    const hideNext = isRtl
      ? slideScrollLeft <= -(slideScrollElement.scrollWidth - slideScrollElement.offsetWidth - 5)
      : slideScrollLeft >= slideScrollElement.scrollWidth - slideScrollElement.offsetWidth - 5;
    const previousFocused = activeElement === previousArrow;
    const nextFocused = activeElement === nextArrow;

    previousArrow.style.setProperty('display', hidePrevious ? 'none' : 'block');
    nextArrow.style.setProperty('display', hideNext ? 'none' : 'block');

    if (hidePrevious && previousFocused) {
      if (hideNext) {
        const activeTab = slideRefs.current[activeSlideIndex];
        activeTab?.focus();
      } else {
        nextArrow.focus();
      }
    }

    if (hideNext && nextFocused) {
      if (hidePrevious) {
        const activeTab = slideRefs.current[activeSlideIndex];
        activeTab?.focus();
      } else {
        previousArrow.focus();
      }
    }
  }, [activeSlideIndex, isRtl, mathRoundKey, slideScrollElement]);

  const queueManageScroll = useCallback(() => {
    if (!isBrowser) return;

    if (scrollManageTimeout.current) {
      window.clearTimeout(scrollManageTimeout.current);
    }

    scrollManageTimeout.current = window.setTimeout(() => {
      manageArrowVisibility();
    }, DEBOUNCE_DELAY);
  }, [manageArrowVisibility]);

  const addHandlers = useCallback(() => {
    if (!isBrowser) return;

    window.addEventListener('resize', queueManageScroll);
    slideScrollElement?.addEventListener('scroll', queueManageScroll);
  }, [queueManageScroll, slideScrollElement]);

  const removeHandlers = useCallback(() => {
    if (!isBrowser) return;

    window.removeEventListener('resize', queueManageScroll);
    slideScrollElement?.removeEventListener('scroll', queueManageScroll);
  }, [queueManageScroll, slideScrollElement]);

  useEffect(() => {
    addHandlers();

    return function () {
      removeHandlers();
    };
  }, [addHandlers, removeHandlers]);

  useEffect(() => {
    if (slideScrollElement !== null) {
      manageArrowVisibility();
    }
  }, [manageArrowVisibility, slideScrollElement]);

  const handleOnClick = async (amenityId: HotelAmenityId | typeof HotelAttributeIds.sale) => {
    resetSuggestedFilter(amenityId);
    sendReward(GOALS.suggestedFilterEngagement);

    await metrics.trackSuggestedFilters(amenityId);
  };

  return (
    <div className="relative flex overflow-hidden pb-2" data-testid="suggested-filters">
      <button
        className="from-bg absolute start-0 flex h-full appearance-none items-center overflow-hidden bg-gradient-to-r focus:duration-100"
        data-testid="hotelAmenitiesLeftArrow"
        type="button"
        onClick={scrollLeft}
        ref={previousArrowRef}
      >
        <Icon name="arrowhead-left" size="xl" />
        <span className="sr-only">{t('amenitySlider.previousSetOfAmenities')}</span>
      </button>
      <button
        className="from-bg absolute end-0 flex h-full appearance-none items-center overflow-hidden bg-gradient-to-l focus:duration-100"
        data-testid="hotelAmenitiesRightArrow"
        type="button"
        onClick={scrollRight}
        ref={nextArrowRef}
      >
        <Icon name="arrowhead-right" size="xl" />
        <span className="sr-only">{t('amenitySlider.nextSetOfAmenities')}</span>
      </button>
      <div
        ref={onSlideScrollRefChange}
        className={cx('flex ltr:ml-1 rtl:mr-1 scrollbar-hide overflow-x-scroll scroll-smooth')}
      >
        {suggestedFilterOrderedList?.map((amenity) => {
          const amenityListCount = amenity?.count;
          const amenitySelected = activeFiltersState.amenityFilters.includes(amenity?.id ?? '');
          return amenityListCount ? (
            <div className="my-2 flex h-10 px-1">
              <button
                type="button"
                name={`${amenity?.name}  (${amenityListCount})`}
                aria-label={`${amenity?.name}  (${amenityListCount})`}
                className={cx(suggestedFilterStyle, {
                  'text-bg bg-primary outline-none':
                    suggestedFilterMVTVariant?.b && amenitySelected,
                  'text-[color:#FFFFFF] bg-[#404040] outline-none':
                    suggestedFilterMVTVariant?.c && amenitySelected,
                  'text-[color:#FFFFFF] bg-[#262626] outline-none':
                    suggestedFilterMVTVariant?.d && amenitySelected,
                })}
                onClick={() => handleOnClick(amenity?.id as HotelAmenityId)}
              >
                <div className="mr-2">
                  <Icon name={AmenityIcons[amenity.id as HotelAmenityId] || amenity.id} size="md" />
                </div>
                <span aria-hidden className={cx('pr-2 text-sm font-bold rtl:pl-2 rtl:pr-1 mt-1')}>
                  <span>{`${amenity.name} (${amenityListCount})`}</span>
                </span>
              </button>
            </div>
          ) : null;
        })}
      </div>
    </div>
  );
};
