import { InfoMessageBanner } from '../info-message-banner/info-message-banner';
import { Trans, useTranslation } from 'next-i18next';
import { useGetMVTSelections, useWrappedRouter } from '../../../hooks';
import { HONORS_LOGIN_BANNER, env } from '../../../constants';
import { LoginIFrame } from '@dx-ui/osc-login';
import { Dialog } from '@dx-ui/osc-dialog';
import { generateSigninUrl } from '../../../utils';
import { useState, useCallback, useEffect, useRef } from 'react';
import { type LoginResponse, useAuth } from '@dx-ui/framework-auth-provider';
import { useIsClient } from 'usehooks-ts';

export const LogInBanner = () => {
  const isClient = useIsClient();
  const { t } = useTranslation('list-view');
  const { router } = useWrappedRouter();
  const { login, isAuthenticated } = useAuth();
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

  //NHCSEARCH-5265 - show honors login banner
  const isOHWSignin = useRef<boolean>(isAuthenticated);
  const { isMVTVariant: honorsBannerMVTVariant } = useGetMVTSelections({
    agentId: HONORS_LOGIN_BANNER.agentId,
    isProvisional: isAuthenticated,
    MVTVariants: ['a', 'b'],
  });

  useEffect(() => {
    const shopSigninSessionVar = window.sessionStorage.getItem('shop-sign-in');
    // set visitor traits
    if (!isAuthenticated) {
      // logged out
      window.sessionStorage.removeItem('shop-sign-in');
    } else if (isAuthenticated && !isOHWSignin.current) {
      // logged in from search
      window.sessionStorage.setItem('shop-sign-in', '1');
    } else if (shopSigninSessionVar !== '1' && isAuthenticated && isOHWSignin.current) {
      // logged in from non-search page
      window.sessionStorage.setItem('shop-sign-in', '0');
    }
  }, [isAuthenticated]);

  const closeLoginModal = () => setShowLoginModal(false);
  const openLoginModal = () => {
    setShowLoginModal(true);
  };

  const lang = router.locale as string;
  const forgetInfoUrl = env.PARTNER_FORGET_INFO_LINK.replace('/__LANG__/', `/${lang}/`);
  const options = {
    showCreatePassword: false,
    forgetInfoLink: { url: forgetInfoUrl },
    secureInfoLink: { url: env.GLOBAL_PRIVACY_URL },
    signInLink: { url: generateSigninUrl(router.asPath, lang) },
  };

  const onSignInAttempt = useCallback(
    async (resp: LoginResponse) => {
      if (resp?.data) {
        await login(resp);
        window.location.reload();
      }
    },
    [login]
  );
  const handleLogin = async (resp: LoginResponse) => {
    await onSignInAttempt?.(resp);
    if (!resp.error) {
      closeLoginModal();
    }
  };

  const ExclusiveRatesLoginBanner = () => (
    <>
      <InfoMessageBanner
        bannerClassName="bg-tier-diamond"
        iconClassName="text-bg"
        iconName="star-burst"
      >
        <div className="text-bg pl-2.5 pt-px font-bold">
          <Trans
            ns="list-view"
            i18nKey="loginBanner.getExclusiveRates"
            components={{
              SignInLink: (
                <button onClick={openLoginModal} type="button" className="font-normal underline">
                  signing in
                </button>
              ),
            }}
          />
        </div>
      </InfoMessageBanner>
      <Dialog
        ariaLabel={t('loginBanner.signIn')}
        isOpen={showLoginModal}
        onDismiss={closeLoginModal}
        size="md"
        title={t('loginBanner.signIn')}
      >
        <LoginIFrame
          data-testid="loginIframe"
          frameSrc={generateSigninUrl(router?.asPath, lang)}
          options={options}
          onLoginAttempt={handleLogin}
          onClose={closeLoginModal}
          title={t('loginBanner.signIn')}
        />
      </Dialog>
    </>
  );
  const HonorsLoginBanner = () => (
    <InfoMessageBanner bannerClassName="bg-success-alt" iconName="happy">
      <div className="pl-2.5 pt-px font-bold">{t('loginBanner.honorsLoginMessage')}</div>
    </InfoMessageBanner>
  );
  if (isClient) {
    return isAuthenticated && honorsBannerMVTVariant.b ? (
      <HonorsLoginBanner />
    ) : !isAuthenticated ? (
      <ExclusiveRatesLoginBanner />
    ) : null;
  }
  return null;
};
