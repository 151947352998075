import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import type { Maybe, ShopMultiPropAvailPointsQuery } from '../../gql/types';
import Carousel from '../carousel/carousel';
import { HotelBadges } from './hotel-badges';
import { useAppDispatch, useAppState } from '../../providers/app-provider';
import { useMediaQuery } from 'usehooks-ts';
import { TripAdvisorRating } from '../trip-advisor/trip-advisor-rating';
import { useMetrics } from '../../hooks';
import { WhyPropertyAccordion } from './why-property-mvt/why-property-accordion';
import type { HotelType } from '../../providers/app-provider/app-provider.types';
import type { RateInfoMessage } from '../rate-name-cta/rate-name-cta';
import { RateNameCTA } from '../rate-name-cta/rate-name-cta';
import { useGetHotelInfo } from './hooks/use-get-hotel-info';
import { CompareHotelsCheckbox } from '../compare-hotels/compare-checkbox';
import { MyWayBenefits } from './myway-benefits-mvt/myway-benefits';

const preventNavigation = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
  e.preventDefault();
};
const preventFocus = (e: React.FocusEvent<HTMLAnchorElement>) => {
  e.preventDefault();
  e.target.blur();
};

const HotelNameHeader = ({
  children,
  name,
  url,
}: {
  children: React.ReactElement;
  name?: string | null;
  url?: Maybe<string>;
}) => {
  if (url) {
    return (
      <>
        {/* This is a bit 'hacky'. We need a link in the page for SEO, but it is non-functional when JS is enabled */}
        <a
          aria-hidden="true"
          className="cursor-default"
          href={url}
          onClick={preventNavigation}
          onFocus={preventFocus}
          tabIndex={-1}
        >
          {children}
        </a>
        {/* For a11y, we're hiding the above link (since it behaves as text) and supplementing it with sr-only content */}
        <h3 className="sr-only">{name}</h3>
      </>
    );
  }
  return children;
};

type HotelCardProps = {
  distance?: string;
  location?: string;
  srLocation?: string;

  isTALoadingState?: boolean;

  onDetailsClick?: () => void;
  showImages?: boolean;

  isListViewOnly?: boolean;
  brandCode?: string;
  renoHotel?: string;
  priceInfo?: ShopMultiPropAvailPointsQuery['shopMultiPropAvail'][0] | null;
  hotelInfo?: HotelType | null | undefined;
} & React.HTMLAttributes<HTMLLIElement>;

const HotelCard = ({
  distance,
  isTALoadingState,
  onDetailsClick,
  showImages,
  isListViewOnly,
  renoHotel,
  priceInfo,
  hotelInfo,
  ...rest
}: HotelCardProps) => {
  const { t } = useTranslation(['hotel-card']);
  const { pageType } = useAppState();
  const smMedViewPort = useMediaQuery('(min-width: 500px) and (max-width: 767px)');
  const metrics = useMetrics();
  const dispatch = useAppDispatch();

  const {
    badges,
    carouselImages,
    ctaLabel,
    ctaHref,
    ctaMessage,
    customParams,
    hotelUrl,
    isHotelDataLoading,
    masterImage,
    rateInfo,
    disclaimerMessages,
    transactionPropertyData,
  } = useGetHotelInfo({
    priceInfo,
    hotelInfo,
    additionalParams: { isRenoHotel: !!renoHotel, isHotelCard: true },
  });
  const hotelName = hotelInfo?.name || '';
  const tripAdvisorLocationSummary = hotelInfo?.tripAdvisorLocationSummary || null;
  const ctyhocn = hotelInfo?.ctyhocn;
  const classname = {
    cardContentClassName: 'mt-auto w-1/2 sm:w-full md:mt-0 md:w-3/5 xl:w-auto self-end',
    propertyNameClassName: 'w-1/2 sm:w-full md:w-2/5 xl:flex-grow rtl:mr-2',
    hotelCardContentClassName: cx('flex w-full', {
      'sm:flex-col': !showImages && !isListViewOnly,
      'sm:justify-end md:flex-row': showImages && !isListViewOnly,
    }),
    wrapperHotelCardHeaderAndCardContentClassName: cx(
      'w-full lg:h-full lg:grid lg:grid-cols-1 lg:items-end',
      {
        'p-2.5': showImages,
      }
    ),
    hotelCardImage: 'w-full justify-center flex',
    distanceClassName: cx('text-xs text-text-alt font-normal whitespace-nowrap', {
      hidden: !!pageType?.isGlobal,
    }),
  };

  const amexDisclaimer = disclaimerMessages?.find((disclaimer: RateInfoMessage) => {
    return disclaimer?.type === 'amexDisclaimer';
  })?.heading?.text;

  const handleOnRateNameCTAClick = () => {
    if (transactionPropertyData) metrics.setPriceClicked(transactionPropertyData);
  };
  const setHighlightedMapCtyhocn = (ctyhocn: string | null) => {
    dispatch({ type: 'SET_HIGHLIGHTED_MAP_CTYHOCN', payload: ctyhocn });
  };
  const getHotelLocationName = () => {
    // In dream on a global page should display (city, state, Country) instead of distance marker
    const address = hotelInfo?.address;
    let location;
    let srLocation;
    if (pageType.isGlobal) {
      // Hotel location name
      location = address?.city;
      if (address?.city) location = address.city;
      if (address?.state) {
        location += location ? `, ${address.state}` : address.state;
      }
      if (address?.country) {
        location += location ? `, ${address.country}` : address.country;
      }
      //screen reader location text
      if (address?.city) srLocation = address.city;
      if (address?.stateName) {
        srLocation += srLocation ? `, ${address.stateName}` : address.stateName;
      }
      if (address?.countryName) {
        srLocation += srLocation ? `, ${address.countryName}` : address.countryName;
      }
    }
    return { location, srLocation };
  };
  const { location, srLocation } = getHotelLocationName();

  return (
    <li
      className="border-border flex w-full justify-self-center rounded-lg border"
      data-testid={`hotel-card-${ctyhocn}`}
      onBlur={() => setHighlightedMapCtyhocn(ctyhocn || null)}
      onFocus={() => setHighlightedMapCtyhocn(null)}
      onMouseEnter={() => setHighlightedMapCtyhocn(ctyhocn || null)}
      onMouseLeave={() => setHighlightedMapCtyhocn(null)}
      {...rest}
    >
      <div className="flex w-full flex-col lg:w-[343px] xl:w-[440px]">
        {showImages && (
          <div className={classname.hotelCardImage} data-testid="hotel-card-image">
            <div className="bg-bg-alt pb-2/3 relative h-0 w-full overflow-hidden rounded-t-lg">
              <Carousel
                data-testid="listViewImageCarousel"
                images={carouselImages ? carouselImages : [masterImage]}
              />
            </div>
          </div>
        )}
        <HotelNameHeader url={hotelUrl} name={hotelName}>
          <h3
            className={cx('w-full break-words font-semibold leading-snug px-2.5 pt-2.5', {
              'text-sm': smMedViewPort,
              'text-base': !smMedViewPort,
            })}
            data-testid="listViewPropertyName"
            id={`hotel-${ctyhocn}`}
          >
            {hotelName}
          </h3>
        </HotelNameHeader>
        {badges ? (
          <div className="pl-2.5">
            <HotelBadges badges={badges} renoHotelLabel={renoHotel} />
          </div>
        ) : null}

        <div
          className={classname.wrapperHotelCardHeaderAndCardContentClassName}
          data-testid="wrapper-hotel-card-content"
        >
          <div className={classname.hotelCardContentClassName} data-testid="hotel-card-content">
            <div className={classname.propertyNameClassName}>
              <button
                className="btn-sm btn-primary-text block self-start font-semibold"
                data-testid={`hotelDetails-${ctyhocn}`}
                onClick={onDetailsClick}
                type="button"
              >
                <span>{t('hotelDetails')}</span>
                <span className="sr-only">&nbsp;{t('forHotel', { hotel: hotelName })}</span>
                <span aria-hidden>&nbsp;&gt;</span>
              </button>
              <span className={classname.distanceClassName} data-testid="distanceMarker">
                {distance}
              </span>
              <span className="sr-only">{srLocation}</span>
              <span
                aria-hidden="true"
                className="text-text-alt whitespace-nowrap text-xs font-normal"
                data-testid="locationMarker"
              >
                {location}
              </span>
              <TripAdvisorRating
                isLoadingState={isTALoadingState}
                showReviewCount={true}
                tripAdvisorLocationSummary={tripAdvisorLocationSummary}
              />
            </div>
            <div className={classname.cardContentClassName}>
              <RateNameCTA
                ctaHref={ctaHref || ''}
                ctaLabel={ctaLabel}
                ctaMessage={ctaMessage}
                customRateInfo={customParams}
                data-testid="priceInfo"
                isLoading={isHotelDataLoading}
                rateMessages={rateInfo}
                onRateNameCTAClick={handleOnRateNameCTAClick}
              />
            </div>
          </div>
          {/* NHCSEARCH-5028 - Why Property MVT */}
          <WhyPropertyAccordion ctyhocn={ctyhocn || ''} />
          <MyWayBenefits
            brandCode={hotelInfo?.brandCode}
            ctyhocn={ctyhocn || ''}
            hotelCountry={hotelInfo?.address?.country || ''}
          />
          {!!customParams?.showCompare ? <CompareHotelsCheckbox ctyhocn={ctyhocn || ''} /> : null}
          {amexDisclaimer ? (
            <div
              className="flex-basis-full shrink-0 grow-0 pt-2.5 text-xs font-normal"
              data-testid="amexDisclaimerMsg"
            >
              {amexDisclaimer}
            </div>
          ) : null}
        </div>
      </div>
    </li>
  );
};

export default HotelCard;
