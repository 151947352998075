import { Dialog } from '@dx-ui/osc-dialog';
import { LoginIFrame, type LoginResponse } from '@dx-ui/osc-login';
import { generateSigninUrl } from '../../utils';
import { useCallback } from 'react';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { env } from '../../constants';
import { useWrappedRouter } from '../../hooks';

export const LoginModal = ({
  onClose,
  onLogin,
  showLoginModal,
  title,
}: {
  onClose: () => void;
  onLogin?: () => void;
  showLoginModal: boolean;
  title: string;
}) => {
  const { login } = useAuth();
  const { router } = useWrappedRouter();
  const lang = router.locale as string;
  const forgetInfoUrl = env.PARTNER_FORGET_INFO_LINK.replace('/__LANG__/', `/${lang}/`);
  const options = {
    showCreatePassword: false,
    forgetInfoLink: { url: forgetInfoUrl },
    secureInfoLink: { url: env.GLOBAL_PRIVACY_URL },
    signInLink: { url: generateSigninUrl(router.asPath, lang) },
  };
  const onSignInAttempt = useCallback(
    async (resp: LoginResponse) => {
      if (resp?.data) {
        await login(resp);
      }
    },
    [login]
  );
  const handleLogin = async (resp: LoginResponse) => {
    await onSignInAttempt?.(resp);
    if (!resp.error) {
      onClose();
      onLogin?.();
    }
  };
  return (
    <Dialog ariaLabel={title} isOpen={showLoginModal} onDismiss={onClose} size="md" title={title}>
      <LoginIFrame
        data-testid="loginIframe"
        frameSrc={generateSigninUrl(router?.asPath, lang)}
        options={options}
        onLoginAttempt={handleLogin}
        onClose={onClose}
        title={title}
      />
    </Dialog>
  );
};
